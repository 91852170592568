import { NavItem } from '@testifysec/wings/dist/shared/data-models';

export const PrimaryNavItems = [
  { label: 'Products', href: '/products/' },
  { label: 'Partners', href: '/partners/' },
  { label: 'About', href: '/about/' },
  { label: 'Contact', href: '/contact/' },
  {
    label: 'Resources',
    navItems: [
      { label: 'Blog', href: '/blog' },
      { label: 'Documentation', href: 'https://witness.dev/docs/' },
      { label: 'Press Kit', href: 'https://testifysec.lingoapp.com/s/dn11kD/?v=0' },
    ],
  },
] as NavItem[];

export const FooterNavItems = [
  { label: 'Report a Vulnerability', href: '/report-a-vulnerability/' },
  { label: 'Privacy Policy', href: '/privacy-policy/' },
  { label: 'Terms and Conditions', href: '/terms-and-conditions/' },
];
