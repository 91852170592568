// Import components and constants
import { Body, Children, Footer, GitLabSvg, GithubSvg, Header, LinkedInSvg, Theme, TwitterXSvg, YoutubeSvg } from '@testifysec/wings';
import { FooterNavItems, PrimaryNavItems } from '../consts';
// Single import statement for React
import React, { useEffect } from 'react';

import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import './DefaultLayout.css';

// Extend the global Window interface for TypeScript
declare global {
  interface Window {
    ldfdr?: (...args: any[]) => void;
  }
}

export interface DefaultLayoutProps {
  children?: Children;
  props?: any;
}

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({ props, children }) => {
  const location = useLocation();
  const isBlog = location?.pathname?.includes('/blog/') && location?.pathname !== '/blog/';
  const isDocs = location?.pathname?.includes('/docs/') && location?.pathname !== '/docs/';

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Initialize window.ldfdr and its _q property if they don't exist
      if (!window.ldfdr) {
        window.ldfdr = ((...args: any[]) => {
          //eslint-disable-next-line
          (window?.ldfdr as any)?._q?.push(args);
        }) as any;
        //eslint-disable-next-line
        (window?.ldfdr as any)._q = [];
      }

      const scriptId = 'ldfdr-tracker';
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = 'https://sc.lfeeder.com/lftracker_v1_lAxoEaK9pro7OYGd.js';
        script.async = true;
        document.body.appendChild(script);
      }
    }
  }, [location.pathname]); // Assuming location is defined. If not, you may need to import or define it.

  const body = <Body className={`${isBlog || isDocs ? 'blog-post container-x container-y md:max-w-2xl' : ''}`}>{children}</Body>;

  return (
    <Theme {...props}>
      <Header
        navItems={PrimaryNavItems}
        actionButtonHref="https://calendly.com/testifysec-team/judge-demo-and-q-a-for-government-contractors-clone"
        actionButtonLabel="Let's Talk"
        componentType={Link} // Removed the `as any` casting
      />
      <div className="overflow-x-hidden">{body}</div>
      <Footer
        bgImage="/codebg.jpeg"
        copyrightOwner="TestifySec Inc."
        footerBlurb="TestifySec unites developers and cybersecurity teams in defending against software supply chain threats by integrating zero trust principles into build pipelines. Everyone deserves secure software."
        phoneNumber="+205-549-1368"
        navItems={FooterNavItems}
        socials={[
          { href: 'https://linkedin.com/company/testifysec', name: 'LinkedIn', icon: LinkedInSvg },
          { href: 'https://gitlab.com/testifysec', name: 'GitLab', icon: GitLabSvg },
          { href: 'https://github.com/testifysec', name: 'Github', icon: GithubSvg },
          { href: 'https://www.youtube.com/channel/UCn3spNYbp-m1RQmep7AddTQ', name: 'Youtube', icon: YoutubeSvg },
          { href: 'https://twitter.com/testifysec', name: 'Twitter', icon: TwitterXSvg },
        ]}
      />
    </Theme>
  );
};
