exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-ads-cato-mdx": () => import("./../../../src/pages/ads/cato.mdx" /* webpackChunkName: "component---src-pages-ads-cato-mdx" */),
  "component---src-pages-ads-ssdf-attestation-mdx": () => import("./../../../src/pages/ads/ssdf-attestation.mdx" /* webpackChunkName: "component---src-pages-ads-ssdf-attestation-mdx" */),
  "component---src-pages-aws-mdx": () => import("./../../../src/pages/aws.mdx" /* webpackChunkName: "component---src-pages-aws-mdx" */),
  "component---src-pages-blog-archivist-intro-mdx": () => import("./../../../src/pages/blog/archivist-intro.mdx" /* webpackChunkName: "component---src-pages-blog-archivist-intro-mdx" */),
  "component---src-pages-blog-attestations-vs-scanning-mdx": () => import("./../../../src/pages/blog/attestations-vs-scanning.mdx" /* webpackChunkName: "component---src-pages-blog-attestations-vs-scanning-mdx" */),
  "component---src-pages-blog-attestations-with-witness-mdx": () => import("./../../../src/pages/blog/attestations-with-witness.mdx" /* webpackChunkName: "component---src-pages-blog-attestations-with-witness-mdx" */),
  "component---src-pages-blog-automating-fedramp-compliance-mdx": () => import("./../../../src/pages/blog/automating-fedramp-compliance.mdx" /* webpackChunkName: "component---src-pages-blog-automating-fedramp-compliance-mdx" */),
  "component---src-pages-blog-aws-marketplace-release-mdx": () => import("./../../../src/pages/blog/aws-marketplace-release.mdx" /* webpackChunkName: "component---src-pages-blog-aws-marketplace-release-mdx" */),
  "component---src-pages-blog-blog-supply-chain-attack-typology-mdx": () => import("./../../../src/pages/blog/blog-supply-chain-attack-typology.mdx" /* webpackChunkName: "component---src-pages-blog-blog-supply-chain-attack-typology-mdx" */),
  "component---src-pages-blog-blog-welcome-chris-h-mdx": () => import("./../../../src/pages/blog/blog-welcome-chris-h.mdx" /* webpackChunkName: "component---src-pages-blog-blog-welcome-chris-h-mdx" */),
  "component---src-pages-blog-blog-welcome-fred-kautz-mdx": () => import("./../../../src/pages/blog/blog-welcome-fred-kautz.mdx" /* webpackChunkName: "component---src-pages-blog-blog-welcome-fred-kautz-mdx" */),
  "component---src-pages-blog-cloud-unfiltered-mdx": () => import("./../../../src/pages/blog/cloud-unfiltered.mdx" /* webpackChunkName: "component---src-pages-blog-cloud-unfiltered-mdx" */),
  "component---src-pages-blog-creating-an-ergonomic-workstation-mdx": () => import("./../../../src/pages/blog/creating-an-ergonomic-workstation.mdx" /* webpackChunkName: "component---src-pages-blog-creating-an-ergonomic-workstation-mdx" */),
  "component---src-pages-blog-crowdstrike-incident-mdx": () => import("./../../../src/pages/blog/crowdstrike-incident.mdx" /* webpackChunkName: "component---src-pages-blog-crowdstrike-incident-mdx" */),
  "component---src-pages-blog-cuture-at-testifysec-mdx": () => import("./../../../src/pages/blog/cuture-at-testifysec.mdx" /* webpackChunkName: "component---src-pages-blog-cuture-at-testifysec-mdx" */),
  "component---src-pages-blog-devsecops-moving-from-implicit-trust-to-explicit-proof-mdx": () => import("./../../../src/pages/blog/devsecops-moving-from-implicit-trust-to-explicit-proof.mdx" /* webpackChunkName: "component---src-pages-blog-devsecops-moving-from-implicit-trust-to-explicit-proof-mdx" */),
  "component---src-pages-blog-funding-announcement-mdx": () => import("./../../../src/pages/blog/funding-announcement.mdx" /* webpackChunkName: "component---src-pages-blog-funding-announcement-mdx" */),
  "component---src-pages-blog-generics-in-witness-mdx": () => import("./../../../src/pages/blog/generics-in-witness.mdx" /* webpackChunkName: "component---src-pages-blog-generics-in-witness-mdx" */),
  "component---src-pages-blog-in-toto-sec-audit-response-mdx": () => import("./../../../src/pages/blog/in-toto-sec-audit-response.mdx" /* webpackChunkName: "component---src-pages-blog-in-toto-sec-audit-response-mdx" */),
  "component---src-pages-blog-intro-mdx": () => import("./../../../src/pages/blog/intro.mdx" /* webpackChunkName: "component---src-pages-blog-intro-mdx" */),
  "component---src-pages-blog-judge-cdk-mdx": () => import("./../../../src/pages/blog/judge-cdk.mdx" /* webpackChunkName: "component---src-pages-blog-judge-cdk-mdx" */),
  "component---src-pages-blog-judge-gitlab-support-mdx": () => import("./../../../src/pages/blog/judge-gitlab-support.mdx" /* webpackChunkName: "component---src-pages-blog-judge-gitlab-support-mdx" */),
  "component---src-pages-blog-keyless-signatures-sigstore-mdx": () => import("./../../../src/pages/blog/keyless-signatures-sigstore.mdx" /* webpackChunkName: "component---src-pages-blog-keyless-signatures-sigstore-mdx" */),
  "component---src-pages-blog-protobom-mdx": () => import("./../../../src/pages/blog/protobom.mdx" /* webpackChunkName: "component---src-pages-blog-protobom-mdx" */),
  "component---src-pages-blog-revolutionizing-agile-workflows-with-github-projects-mdx": () => import("./../../../src/pages/blog/revolutionizing-agile-workflows-with-github-projects.mdx" /* webpackChunkName: "component---src-pages-blog-revolutionizing-agile-workflows-with-github-projects-mdx" */),
  "component---src-pages-blog-secure-supply-chain-with-archivista-mdx": () => import("./../../../src/pages/blog/secure-supply-chain-with-archivista.mdx" /* webpackChunkName: "component---src-pages-blog-secure-supply-chain-with-archivista-mdx" */),
  "component---src-pages-blog-shifting-liability-in-the-supply-chain-mdx": () => import("./../../../src/pages/blog/shifting-liability-in-the-supply-chain.mdx" /* webpackChunkName: "component---src-pages-blog-shifting-liability-in-the-supply-chain-mdx" */),
  "component---src-pages-blog-sigstore-vs-in-toto-mdx": () => import("./../../../src/pages/blog/sigstore-vs-in-toto.mdx" /* webpackChunkName: "component---src-pages-blog-sigstore-vs-in-toto-mdx" */),
  "component---src-pages-blog-software-supply-chain-history-of-failure-mdx": () => import("./../../../src/pages/blog/software-supply-chain-history-of-failure.mdx" /* webpackChunkName: "component---src-pages-blog-software-supply-chain-history-of-failure-mdx" */),
  "component---src-pages-blog-ssdf-attestation-mdx": () => import("./../../../src/pages/blog/ssdf-attestation.mdx" /* webpackChunkName: "component---src-pages-blog-ssdf-attestation-mdx" */),
  "component---src-pages-blog-ssdf-compiance-with-witness-mdx": () => import("./../../../src/pages/blog/ssdf_compiance_with_witness.mdx" /* webpackChunkName: "component---src-pages-blog-ssdf-compiance-with-witness-mdx" */),
  "component---src-pages-blog-the-zero-trust-clock-is-ticking-mdx": () => import("./../../../src/pages/blog/the_zero_trust_clock_is_ticking.mdx" /* webpackChunkName: "component---src-pages-blog-the-zero-trust-clock-is-ticking-mdx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-blog-turtles-all-the-way-down-mdx": () => import("./../../../src/pages/blog/turtles-all-the-way-down.mdx" /* webpackChunkName: "component---src-pages-blog-turtles-all-the-way-down-mdx" */),
  "component---src-pages-blog-what-is-a-supply-chain-attestation-mdx": () => import("./../../../src/pages/blog/what-is-a-supply-chain-attestation.mdx" /* webpackChunkName: "component---src-pages-blog-what-is-a-supply-chain-attestation-mdx" */),
  "component---src-pages-blog-what-is-the-ssdf-mdx": () => import("./../../../src/pages/blog/what-is-the-ssdf.mdx" /* webpackChunkName: "component---src-pages-blog-what-is-the-ssdf-mdx" */),
  "component---src-pages-blog-why-i-joined-testifysec-tanner-jones-mdx": () => import("./../../../src/pages/blog/why-i-joined-testifysec-tanner-jones.mdx" /* webpackChunkName: "component---src-pages-blog-why-i-joined-testifysec-tanner-jones-mdx" */),
  "component---src-pages-blog-why-standardized-attestations-matter-mdx": () => import("./../../../src/pages/blog/why-standardized-attestations-matter.mdx" /* webpackChunkName: "component---src-pages-blog-why-standardized-attestations-matter-mdx" */),
  "component---src-pages-blog-witness-donation-mdx": () => import("./../../../src/pages/blog/witness-donation.mdx" /* webpackChunkName: "component---src-pages-blog-witness-donation-mdx" */),
  "component---src-pages-blog-witness-gitlab-component-mdx": () => import("./../../../src/pages/blog/witness-gitlab-component.mdx" /* webpackChunkName: "component---src-pages-blog-witness-gitlab-component-mdx" */),
  "component---src-pages-blog-zero-trust-architecture-product-mindset-mdx": () => import("./../../../src/pages/blog/zero-trust-architecture-product-mindset.mdx" /* webpackChunkName: "component---src-pages-blog-zero-trust-architecture-product-mindset-mdx" */),
  "component---src-pages-blog-zero-trust-in-the-supply-chain-mdx": () => import("./../../../src/pages/blog/zero-trust-in-the-supply-chain.mdx" /* webpackChunkName: "component---src-pages-blog-zero-trust-in-the-supply-chain-mdx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-mdx": () => import("./../../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-docs-aws-getting-started-with-judge-eks-mdx": () => import("./../../../src/pages/docs/aws/getting-started-with-judge-eks.mdx" /* webpackChunkName: "component---src-pages-docs-aws-getting-started-with-judge-eks-mdx" */),
  "component---src-pages-docs-aws-values-mdx": () => import("./../../../src/pages/docs/aws/values.mdx" /* webpackChunkName: "component---src-pages-docs-aws-values-mdx" */),
  "component---src-pages-docs-helm-archivista-mdx": () => import("./../../../src/pages/docs/helm/archivista.mdx" /* webpackChunkName: "component---src-pages-docs-helm-archivista-mdx" */),
  "component---src-pages-docs-helm-configuring-judge-helm-mdx": () => import("./../../../src/pages/docs/helm/configuring-judge-helm.mdx" /* webpackChunkName: "component---src-pages-docs-helm-configuring-judge-helm-mdx" */),
  "component---src-pages-docs-helm-getting-started-with-judge-helm-mdx": () => import("./../../../src/pages/docs/helm/getting-started-with-judge-helm.mdx" /* webpackChunkName: "component---src-pages-docs-helm-getting-started-with-judge-helm-mdx" */),
  "component---src-pages-docs-helm-judge-api-mdx": () => import("./../../../src/pages/docs/helm/judge-api.mdx" /* webpackChunkName: "component---src-pages-docs-helm-judge-api-mdx" */),
  "component---src-pages-docs-helm-judge-mdx": () => import("./../../../src/pages/docs/helm/judge.mdx" /* webpackChunkName: "component---src-pages-docs-helm-judge-mdx" */),
  "component---src-pages-docs-helm-judge-web-mdx": () => import("./../../../src/pages/docs/helm/judge-web.mdx" /* webpackChunkName: "component---src-pages-docs-helm-judge-web-mdx" */),
  "component---src-pages-docs-helm-kratos-mdx": () => import("./../../../src/pages/docs/helm/kratos.mdx" /* webpackChunkName: "component---src-pages-docs-helm-kratos-mdx" */),
  "component---src-pages-docs-helm-kratos-selfservice-ui-node-mdx": () => import("./../../../src/pages/docs/helm/kratos-selfservice-ui-node.mdx" /* webpackChunkName: "component---src-pages-docs-helm-kratos-selfservice-ui-node-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-landing-cake-mdx": () => import("./../../../src/pages/landing/cake.mdx" /* webpackChunkName: "component---src-pages-landing-cake-mdx" */),
  "component---src-pages-landing-technet-mdx": () => import("./../../../src/pages/landing/technet.mdx" /* webpackChunkName: "component---src-pages-landing-technet-mdx" */),
  "component---src-pages-partners-mdx": () => import("./../../../src/pages/partners.mdx" /* webpackChunkName: "component---src-pages-partners-mdx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-products-mdx": () => import("./../../../src/pages/products.mdx" /* webpackChunkName: "component---src-pages-products-mdx" */),
  "component---src-pages-report-a-vulnerability-mdx": () => import("./../../../src/pages/report-a-vulnerability.mdx" /* webpackChunkName: "component---src-pages-report-a-vulnerability-mdx" */),
  "component---src-pages-terms-and-conditions-mdx": () => import("./../../../src/pages/terms-and-conditions.mdx" /* webpackChunkName: "component---src-pages-terms-and-conditions-mdx" */),
  "component---src-pages-thanks-mdx": () => import("./../../../src/pages/thanks.mdx" /* webpackChunkName: "component---src-pages-thanks-mdx" */)
}

